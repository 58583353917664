<template>
  <button class="back-btn" @click="$router.go(-1)">
    <q-icon name="fas fa-chevron-left" color="primary" size="20px" />
  </button>
</template>

<script>
export default {

}
</script>

<style lang="stylus" scoped>
.back-btn
  position fixed
  left 5px
  top 10px
  z-index 2
  border-radius 50%
  margin-right 10px
  width 36px
  height 36px
  background rgba(255, 255, 255, 1)
  border 1px solid rgba(0, 0, 0, 0.2)
  padding 0
</style>
